@import 'fonts';
@import 'variables';
@import 'animations';
@import 'button';
@import 'section';

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
	scroll-padding-top: 120px;
}

body {
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	background-color: $white-color;

	&::-webkit-scrollbar-track {
		background-color: $light-gray-color;
	}

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $gray-color;
	}

	.container-fluid {
		max-width: 1200px;
	}
}
