@import 'variables';

.section {
    padding: 7em 1rem;

    &-content {

        h2 {
            margin-bottom: 2rem;
            font-size: clamp(1.8rem, 5vw, 3rem) !important;
            font-weight: 800;
            text-transform: none !important;
        }

        &.margin-zero {
            p {
                margin: 0;
                padding: 1.5rem;
                font-size: clamp(1.45rem, 2.43vw, 1.6rem);
            }
        }

        h3,
        p,
        ul {
            margin-bottom: 2rem;
        }

        p,
        ul {
            color: $description-color;
            font-weight: 500;
        }

        h3,
        h4 {
            font-size: clamp(1.6rem, 4.5vw, 2rem);
            font-weight: 800;
            line-height: 3rem;
        }

        p {
            font-size: clamp(1.4rem, 4vw, 1.6rem);
            line-height: 2.5rem;
        }

        ul {
            padding-left: 0;
            font-size: clamp(1.4rem, 4vw, 1.6rem);
            line-height: 2.5rem;
            list-style: none;

            i {
                margin-right: 1.5rem;
                color: $black-color;
            }
        }
    }

    &-supertitle,
    &-title {
        margin-bottom: 2rem;
        text-transform: uppercase;
    }

    &-supertitle {
        display: flex;
        align-items: center;
        font-size: clamp(1.8rem, 5vw, 2.2rem);
        font-weight: 700;
        line-height: 2.6rem;

        &::before {
            content: '';
            margin-right: 3rem;
            width: 40px;
            height: 3px;
            background-color: $black-color;
        }
    }

    &-title {
        font-size: clamp(2.2rem, 7.2vw, 3.5rem);
        font-weight: 800;
        line-height: 4rem;
    }
}