@import '../../styles/variables';

.doors {
    &-carousel {
        padding: 0 0 4rem 0;
        height: 100vh;
        max-height: 550px;
        overflow: hidden;

        .swiper-slide {
            position: relative;
            min-height: 100%;
            cursor: grab;
            user-select: none;
            overflow: hidden;

            img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -3;
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
            }

            &::before {
                top: 0;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: -1;
            }

            &::after {
                top: 50%;
                transform: translateY(-50%);
                height: 10%;
                transition: height 0.5s, top 0.5s, transform 0.5s, opacity 0.5s;
                transform-origin: center;
                background-color: rgba(0, 0, 0, 0.7);
                opacity: 0;
            }

            &:hover,
            &.swiper-slide-active {
                .doors-carousel-content {
                    border-color: $yellow-color;

                    .button {
                        transform: translateY(0);
                    }
                }

                &::after {
                    top: 0;
                    height: 100%;
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }

        &-content {
            position: absolute;
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;
            padding: 1rem;
            width: auto !important;
            height: auto !important;
            border: 5px solid $white-color;
            transition: border-color 0.5s;
            overflow: hidden;
            z-index: 1;

            .button {
                font-size: 1.4rem;
                font-weight: 600;
                text-transform: none;
                transition: transform 0.5s;
                transform: translateY(400%);
                background-color: $yellow-color;

                &::after {
                    display: none;
                }

                &:hover {
                    color: $black-color;
                    text-decoration: underline !important;
                }
            }
        }

        &-title {
            display: flex;
            align-items: flex-start;
            margin: 0 0 3rem 0;
            min-height: 9rem;
            color: $white-color;
            font-size: clamp(2rem, 5.5vw, 2.6rem);
            font-weight: 800;
            line-height: 1.4;
        }

        .swiper-pagination {
            bottom: 0;

            &-bullet {
                margin: 0 0.7rem !important;
                width: 12px;
                height: 12px;
                background-color: rgb(0, 0, 0);
                border-radius: 0;
                opacity: 1;

                &-active {
                    background-color: $yellow-color;
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .doors {
        &-carousel {
            &-title {
                font-size: clamp(2rem, 3.4vw, 2.6rem);
            }
        }
    }
}

@media (min-width: 768px) {
    .doors {
        &-carousel {
            &-title {
                font-size: clamp(1.8rem, 2.3vw, 2.6rem);
            }
        }
    }
}

@media (min-width: 992px) {
    .doors {
        &-carousel {
            &-title {
                font-size: clamp(1.7rem, 1.71vw, 2.1rem);
            }
        }
    }
}

@media (min-width: 1200px) {
    .doors {
        &-carousel {
            &-title {
                font-size: 2.1rem;
            }
        }
    }
}