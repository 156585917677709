@import '../../styles/variables';

.counters {
    &-section {
        position: relative;
        color: $yellow-color;
        overflow: hidden;

        &::before,
        &::after {
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &::before {
            position: absolute;
            background-image: url('../../assets/img/counters/counters-bg-sm.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -5;
        }

        &::after {
            position: absolute;
            background-color: rgba(0, 0, 0, 0.75);
            z-index: -1;
        }

        i {
            margin-bottom: 2.5rem;
            font-size: clamp(5rem, 8vw, 6rem);
        }
    }

    &-count {
        font-size: clamp(5rem, 8vw, 6rem);
        font-weight: 700;
    }

    &-title {
        display: flex;
        align-items: center;
        font-size: clamp(1.4rem, 2.4vw, 1.7rem);
        font-weight: 400;
        font-style: italic;

        &::before,
        &::after {
            content: '';
            width: 50px;
            height: 2px;
            background-color: $yellow-color;
        }

        &::before {
            margin-right: 2rem;
        }

        &::after {
            margin-left: 2rem;
        }
    }
}

@media (min-width: 576px) {
    .counters {
        &-section {
            &::before {
                background-image: url('../../assets/img/counters/counters-bg-md.jpg');
            }
        }
    }
}

@media (min-width: 1200px) {
    .counters {
        &-section {
            &::before {
                background-image: url('../../assets/img/counters/counters-bg-lg.jpg');
            }
        }
    }
}