@import '../../styles/variables';

.contact {
    &-section {
        &-image {
            max-width: 450px;
            object-fit: cover;
        }
    }

    &-form {
        &-input {
            font-size: 1.5rem !important;
            font-family: 'Montserrat', sans-serif !important;
            font-weight: 500 !important;
        }

        .textarea {
            min-height: 120px;
        }

        &-checkbox {
            position: relative;
            appearance: none;
            margin: 0;
            padding: 0;
            min-width: 20px;
            min-height: 20px;
            width: 20px;
            height: 20px;
            max-width: 20px;
            max-height: 20px;
            background: none;
            -webkit-tap-highlight-color: transparent;
            cursor: pointer;

            &:focus:not(.error) {
                outline: transparent;
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &::before {
                background-color: transparent;
                border: 1px solid $light-gray-color;
                z-index: 1;
            }

            &::after {
                background-color: $light-gray-color;
                transform: scale(0);
                transition: transform 0.3s;
                z-index: 1;
            }

            &:checked::after {
                transform: scale(0.65);
            }
        }

        &-consent {
            margin-left: 1.5rem;
            color: $light-gray-color;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.5;
            cursor: pointer;
            user-select: none;
        }

        input {
            &.error {
                outline: 1px solid #ff0000;
            }
        }
    }

    &-box {
        &-icon {
            min-width: 60px;
            max-width: 60px;
            min-height: 60px;
            max-height: 60px;
            background-color: $yellow-color;

            i {
                font-size: 2.6rem;
            }
        }

        &-title {
            font-size: clamp(1.3rem, 3.5vw, 1.8rem);
            font-weight: 700;
        }

        &-inner {

            a,
            p {
                color: $description-color;
                font-size: clamp(1rem, 3.2vw, 1.5rem);
                font-weight: 500;
                line-height: 2.4rem;
                text-decoration: none;
            }

            a:hover {
                color: $description-color;
                text-decoration: underline;
            }
        }
    }
}

@media (min-width: 768px) {
    .contact {
        &-section {
            &-image {
                max-width: 100%;
            }
        }
    }
}