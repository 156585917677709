@keyframes showUp {
    0% {
        transform: translateY(5%);
        opacity: 0;
        visibility: hidden;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(100%)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes loaderDropDown {
    0% {
        transform: translate(-50%, -100%);
        opacity: 0;
    }

    100% {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
}

@keyframes loaderRotate {
    0% {
        transform: rotateZ(360deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}

@keyframes statusImage {
    0% {
        transform: translateY(50%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}