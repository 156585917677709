.button {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1.2rem 2rem;
    width: max-content;
    color: $black-color;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    background-color: $yellow-color;
    border: none;
    border-radius: 0;
    transition: color 0.3s;
    z-index: 0;

    i {
        margin-left: 1rem;
        font-size: 1.2rem;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        background: $black-color;
        transition: width 0.3s, height 0.3s;
        z-index: -1;
    }

    &:hover,
    &:focus {
        color: $white-color;
        background-color: $yellow-color;
        border: none;
        box-shadow: none;

        &::after {
            width: 100%;
            height: 100%;
        }
    }

    &:focus {
        outline: 1px solid $yellow-color !important;

        &::after {
            outline: 1px solid $yellow-color !important;
        }
    }
}