@import '../../styles/variables';

.loader {
    &-wrapper {
        transform: translate(-50%, -100%);
        animation: loaderDropDown 1s linear forwards;

        img {
            width: 120px;
            height: 120px;
        }
    }

    &-inner {
        top: -10px;
        width: 140px;
        height: 140px;
        border: 2px dashed $black-color;
        transition: transform 0.5s, opacity 0.5s;
        animation: loaderRotate 1s linear infinite;
    }

    &-text {
        font-size: 2.4rem;
    }
}

@media (min-width: 576px) {
    .loader-text {
        font-size: 3.2rem;
    }
}

@media (min-width: 768px) {
    .loader-text {
        font-size: 4.2rem;
    }
}