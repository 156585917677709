@import '../../styles/variables';

.interior-doors {
    &-section {
        .question-button {
            margin: 3rem auto 0;
        }
    }

    &-tab {
        min-width: 200px;
        font-size: clamp(1.7rem, 4vw, 2.2rem);
        font-weight: 800;
        line-height: 3.6rem;
        text-align: center;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: $yellow-color;
            opacity: 0;
            transition: opacity 0.5s;
        }

        &.active::before {
            opacity: 1;
        }
    }
}