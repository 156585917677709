@import '../../styles/variables';

.modal-form {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    padding: 1rem;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.96);
    opacity: 0;
    transition: opacity 0.5s;
    transform: translateY(200%);
    z-index: 10;

    &.show {
        transform: translateY(0);
        opacity: 1;
    }

    &-content {
        padding: 3rem;
        width: 100%;
        max-width: 500px;
        background-color: $white-color;
    }

    &-input {
        font-size: 1.4rem !important;
        font-family: 'Montserrat', sans-serif !important;
        font-weight: 500 !important;
    }

    &-checkbox {
        position: relative;
        appearance: none;
        margin: 0;
        padding: 0;
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
        max-width: 20px;
        max-height: 20px;
        background: none;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;

        &:focus:not(.error) {
            outline: transparent;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &::before {
            background-color: transparent;
            border: 1px solid $light-gray-color;
            z-index: 1;
        }

        &::after {
            background-color: $light-gray-color;
            transform: scale(0);
            transition: transform 0.3s;
            z-index: 1;
        }

        &:checked::after {
            transform: scale(0.65);
        }
    }

    &-consent {
        margin-left: 1.5rem;
        color: $light-gray-color;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.5;
        cursor: pointer;
        user-select: none;
    }

    input,
    select {
        &.error {
            outline: 1px solid #ff0000;
        }
    }
}